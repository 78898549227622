import React, { useState } from "react";
import Layout from "../components/layout";
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import SEO from "../components/seo";
import { Typography, Grid, TextField, Button } from "@material-ui/core";
import WineDescription from "../components/wine-description";
import AromaWheel from "../components/aroma-wheel";

export default function AddPage() {
  const [wine, setWine] = useState({});
  const [wineSubmitted, setWineSubmitted] = useState(false);

  let description;
  let aromaWheel;

  description = <WineDescription name={wine.name} vintage={wine.vintage} region={wine.region} />
  aromaWheel = <AromaWheel />;

  const handleWineSubmitted = (wine) => {
    setWine(wine);
    setWineSubmitted(true);
  }
  return (
    <Layout>
      <SEO title={"Add Wine"} />

      <Typography variant="h5" component="h2" gutterBottom>
          What are you drinking?
      </Typography>

      <WineForm onSubmit={(wine) => setWine(wine)} submitted={wineSubmitted} />

      {description}
      {aromaWheel}
      
    </Layout>
  );
}

function WineForm(props) {
  const [name, setName] = useState(null);
  const [vintage, setVintage] = useState(null);
  const [region, setRegion] = useState(null);

  const onSubmit = (e) => {
    props.onSubmit({name, vintage, region});
    e.preventDefault(); // Don't let the page refresh.
  }

  let submitButton;
  
  if (!props.submitted) {
    submitButton = 
      <Grid container item xs justify="flex-end">
        <Button variant="contained" color="primary" margin="normal"
          endIcon={<NavigateNextIcon />} type="submit">
          Next: Aroma
        </Button>
      </Grid>;
  }
  return (
    <form onSubmit={onSubmit}>
      <fieldset disabled={props.submitted ? true : false}>
        <Grid container>
          <Grid item xs>
            <TextField
              onChange={event => setName(event.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
              label="Wine name" />
          </Grid>
          <Grid container alignItems="stretch" spacing={1}>
            <Grid item xs>
              <TextField
                onChange={event => setVintage(event.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
                label="Vintage" />
            </Grid>
            <Grid item xs>
              <TextField
                onChange={event => setRegion(event.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
                label="Region" />
            </Grid>
          </Grid>
          {submitButton}
        </Grid>
      </fieldset>
    </form>
  );
}