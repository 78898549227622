import React from 'react';

export default function WineDescription(props) {
  return (
  <div>
    {props.name || 'Not set'} + {' '}
    {props.vintage || 'Not set'} + {' '}
    {props.region || 'Not set'}
  </div>
  );
}