import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyle = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    backgroundColor: '#F6E27F',
    borderRadius: '50%',
    color: '#333333',
    display: 'flex',
    height: '40rem',
    justifyContent: 'center',
    margin: '0 auto',
    width: '40rem',
  },
  label: {

  }
}));

export default function AromaWheel(props) {
  const classes = useStyle();
  return (
    <div className={classes.root}>
      <Typography variant="h1" component="div">
        Aromas
      </Typography>
    </div>
  );
}